import { createStore, createLogger } from 'vuex';

const debug = process.env.NODE_ENV !== 'production';

const getDefaultState = () => ({ });

export default createStore({
  state: getDefaultState(),
  strict: false,
  plugins: debug ? [createLogger()] : [],
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
});
