/* eslint-disable max-len */

import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createHead } from '@vueuse/head';
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import InlineSvg from 'vue-inline-svg';
import smoothscroll from 'smoothscroll-polyfill';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';

import store from './store';

import messagesDE from './assets/language/de.json';
import messagesFR from './assets/language/fr.json';
import messagesIT from './assets/language/it.json';

import './assets/styles/main.scss';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// i18n
// --------------------------------

const i18n = createI18n({
  locale: 'de',
  messages: { de: messagesDE, fr: messagesFR, it: messagesIT },
});

// router
// --------------------------------

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: () => import('./components/pages/Start.vue') },
    { path: '/fr2rikn3c0yyveywlkibikwzmaehktdvxz7vjox9', component: () => import('./components/pages/Admin.vue') },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { el: to.hash, behavior: 'smooth' };
    if (savedPosition) return savedPosition;
    return { top: 0 };
  },
});

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);
app.use(i18n);
app.use(router);
app.use(store);
app.use(VueAxios, axios);

app.component('InlineSvg', InlineSvg);

window[atob('cGF0cmljaw==')] = atob('U3RpbGxoYXJ0IHN0eWxlZCBoYXJk');

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.mount('#app');
