<template>
  <!-- eslint-disable max-len -->

  <div class="Language text-blue">
    <div class="relative inline-flex">
      <svg class="w-2 h-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path
        d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
        fill="currentColor"
        fill-rule="nonzero"
        stroke-width="10"
      /></svg>
      <select v-model="$i18n.locale" class="h-10 pr-8 appearance-none">
        <option value="de">
          DE
        </option>
        <option value="fr">
          FR
        </option>
        <option value="it">
          IT
        </option>
      </select>
    </div>

  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

select {
  background: transparent;
  option {
    background: var(--sand-light);
  }
}

svg {
  transform: scale(1.5);
}

</style>
